
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['close'],
    props: {
        title: {
            type: String
        }
    }
});
